import Mock from "../mock";

const database = {
  information: {
    name: 'Daniel Cederberg',
    aboutContent: "Front-End / Full stack Web Developer with over 10 years of experience in the industry. If you need organized team player with clean code and with pixel perfect results, look no further.",
    age: 31,
    phone: '',
    nationality: 'Finnish',
    language: 'English, Finnish',
    email: 'daniel@dcsolutions.fi',
    address: 'Porvoo, FINLAND',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/daniel-cederberg-4b25b7124/',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/mehello.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/cv-daniel-cederberg.pdf'
  },
  services: [
    {
      title: "Front-end Development",
      icon: 'color-pallet',
      details: "My strenghts are in the front-end technology. I have most experience in JavaScript, React.js and of course HTML5/CSS3."
    },
    {
      title: "Back-end Development",
      icon: 'code',
      details: "Back-end development is no stranger to me either. Have done several projects using PHP, MySQL. Also learning Node.js, but still a rookie with that :)"
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Mobile App Deveploment is my youngest skill. Have done some simple projects with React Native and PhoneGap."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Marjo Käyhkö',
        designation: 'Development Manager, Kindred Group plc'
      }
    }/*,
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Eric Cederberg',
        designation: 'CTO, Good Luck Media Ltd'
      }
    }*/
  ],
  skills: [
    {
      title: "HTML5/CSS3",
      value: 95
    },
    {
      title: "Javascript",
      value: 80
    },
    {
      title: "jQuery",
      value: 90
    },
    {
      title: "ReactJS/Redux",
      value: 80
    },
    {
      title: "React Native",
      value: 65
    },
    {
      title: "Vue.js",
      value: 65
    },
    {
      title: "PHP",
      value: 80
    },
    {
      title: "Laravel/Lumen",
      value: 70
    },
    {
      title: "Bootsrap",
      value: 80
    },
    {
      title: "Sass/Less",
      value: 90
    },
    {
      title: "Git",
      value: 80
    },
    {
      title: "Wordpress",
      value: 85
    },
    {
      title: "Photoshop",
      value: 70
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Win A Tesla",
      subtitle: "Interactive quiz.",
      codeUsed: "HTML/CSS, Javascript, jQuery",
      imageUrl: "/images/tesla.jpg",
      largeImageUrl: ["/images/tesla-lg.jpg"],
      url: 'http://www.win-a-tesla.com/'
    },
    {
      id: 2,
      title: "Lotto PWA",
      subtitle: "Finds lotto results.",
      codeUsed: "ReactJS, CSS",
      imageUrl: "/images/lotto.jpg",
      largeImageUrl: ["/images/lotto-lg.jpg"],
      url: 'https://widget.lottoarvonta.com/lotto'
    },
    {
      id: 3,
      title: "Pokerisivut",
      subtitle: "Largest poker forum in FInland from Drupal to WordPress.",
      codeUsed: "HTML/CSS, Javascript, jQuery, PHP, mySQL, Apache",
      imageUrl: "/images/pokerisivut.jpg",
      largeImageUrl: ["/images/pokerisivut-lg.jpg"],
      url: 'https://www.pokerisivut.com'
    },
    {
      id: 4,
      title: "Pokeritieto",
      subtitle: "Poker forum from Drupal/PHPbb to WordPress/bbPress.",
      codeUsed: "HTML/CSS, Javascript, jQuery, PHP, mySQL",
      imageUrl: "/images/pokeritieto.jpg",
      largeImageUrl: ["/images/pokeritieto-lg.jpg"],
      url: 'https://www.pokeritieto.com'
    },
    {
      id: 5,
      title: "Alimetrics",
      subtitle: "A Worldwide Sample Analysis Service For Intestinal Health Markers",
      codeUsed: "HTML/CSS, Javascript, jQuery",
      imageUrl: "/images/ali.jpg",
      largeImageUrl: ["/images/ali-lg.jpg"],
      url: 'http://alimetrics.com/'
    },
    {
      id: 6,
      title: "Team Projects",
      subtitle: "Big websites where i have been a part of as a Front-End developer.",
      codeUsed: "kolikkopelit.com, casinohuone.com, igame.com",
      imageUrl: "/images/team.jpg",
      largeImageUrl: ["/images/team.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2017 - 2020",
        position: "Front-End/Full Stack Web Developer",
        company: "Kindred Group plc",
        details: "Over 3 year full time contract. Started in a team as a Front-End web developer in one of the most biggest finnish casino sites. Then moved to rebuilding and switching platforms for some huge affiliate sites."
      },
      {
        id: 2,
        year: "2009 - 2017",
        position: "Front-End Web Developer",
        company: "Diversion52 Oy",
        details: "Handled everything from building web sites and stores to customer reliations. Also modified own cms from WordPress, created multiple plugins, setup and maintained linux/debian web servers, planned and created marketing campaigns."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2007 - 2009",
        graduation: "Vocational Qualification in Business Information Technology",
        university: "Porvoon Yrityspalvelu (Porvoo's Business school)",
        details: ""
      },
      {
        id: 2,
        year: "2014 ->",
        graduation: "Online Courses",
        university: "udemy.com",
        details: "The Modern JavaScript Bootcamp\n Modern React with Redux [2019 Update]\n Advanced React and Redux: 2019 Edition\n The Complete React Native + Hooks Course [2019 Edition]\n Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)\n PHP with Laravel for beginners - Become a Master in Laravel\n Progressive Web Apps (PWA) - The Complete Guide\n Mastering Agile Scrum Project Management"
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: "React new version is coming!",
      imageUrl: "/images/blog-image-1.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 2,
      title: "More about react hooks",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "04 December 2019"
    },
    {
      id: 3,
      title: "Next generation javascript learning source",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "05 December 2019"
    },
    {
      id: 4,
      title: "Free tutorial downloading link.",
      imageUrl: "/images/blog-image-2.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "06 December 2019"
    },
    {
      id: 5,
      title: "Get 2500 free mockup for design.",
      youtubeUrl: "TKnufs85hXk",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "08 December 2019"
    },
    {
      id: 6,
      title: "React vs Vue vs Angular, what is best?",
      vimeoUrl: "23534361",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "10 December 2019"
    },
    {
      id: 7,
      title: "Web design typography & spacing.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 8,
      title: "React new version is coming with a great features.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+358 44 350 7113'],
    skypeName: "daniel.cederberg",
    emailAddress: ['dcposti@gmail.com'],
    address: "Porvoo, FINLAND"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});